<template>
  <v-dialog persistent v-model="show" transition="dialog-bottom-transition">
    <v-card>
      <v-card-title class="text-overline font-weight-6 primary--text justify-center">Unsupported Browser</v-card-title>
      <v-card-text class="text-caption pb-3 grey--text text--darken-1 justify-center">
        <p v-if="source == 'pwa'">
          Please tap <small><v-icon>mdi-dots-horizontal</v-icon></small> and choose <v-icon dense>mdi-web</v-icon> <small class="text-caption font-weight-bold grey--text text--darken-2">Open in browser</small> and tap the same button to install our app
        </p>
        <p v-else-if="source == 'login'">
          Please tap <small><v-icon>mdi-dots-horizontal</v-icon></small> and choose <v-icon dense>mdi-web</v-icon> <small class="text-caption font-weight-bold grey--text text--darken-2">Open in browser</small> to sign in with Google / Facebook
        </p>
        <p v-else>
          Please tap <small><v-icon>mdi-dots-horizontal</v-icon></small> and choose <v-icon dense>mdi-web</v-icon> <small class="text-caption font-weight-bold grey--text text--darken-2">Open in browser</small>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="font-weight-6"
          small
          text
          plain
          @click="show = false"
        >
          Got it!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: ['visible', 'source'],
  data() {
    return {};
  },
  computed: {
    show: {
      get: function () {
        return this.visible;
      },
      set: function (val) {
        if (!val) {
          this.$emit('close', false);
        }
      },
    },
  },
};
</script>

<style scoped>
</style>
